import React from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
    outlet: JSX.Element;
    restricted?: boolean;
}

export const PublicRoute = (props: Props) => {
    const { outlet, restricted = false } = props;
    const isAuthenticated = !!localStorage.getItem('authToken');

    if (isAuthenticated && restricted) {
        return <Navigate to={{ pathname: '/' }} />;
    } else {
        return outlet;
    }
};