import React from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.scss';

interface IProps {
    label?: string;
    errorText?: string;
    selectedValue?: Date | null | undefined;
    placeholder?: string;
    onSelect?: () => void;
    onChange: (date: Date | null, event: React.SyntheticEvent<EventTarget>) => void;
    maxDate?: Date;
}

export const DatePicker: React.FC<IProps> = (props) => {
    const { label, errorText, selectedValue, placeholder, onSelect, onChange, maxDate } = props;

    return (
        <div role="group" className="form-group">
            <label>{label}</label>
            <div className="row align-items-center">
                <ReactDatePicker
                    className="form-control"
                    placeholderText={placeholder}
                    onSelect={onSelect}
                    onChange={onChange}
                    selected={selectedValue}
                    showYearDropdown
                    dateFormat="dd.MM.yyyy"
                    maxDate={maxDate}
                />
            </div>

            {errorText && <p className="inputError">{errorText}</p>}
        </div>
    );
};
