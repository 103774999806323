// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header {
  background-color: #ffffff;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  padding: 20px 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/header.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,mDAAA;EACA,kBAAA;AADJ","sourcesContent":["@import '../../sass/common/variables';\r\n\r\n.Header {\r\n    background-color: #ffffff;\r\n    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);\r\n    padding: 20px 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
