import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { PublicRoute } from './PublicRoute';

import { Home } from 'src/features/home/Home';

export const Router = () => {
    return (
        <Routes>
            <Route path='/' element={<PublicRoute outlet={<Home />} />} />
        </Routes>
    );
};
