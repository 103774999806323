import React from 'react';
import PhoneInputLib from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import './phoneInput.scss';

interface IProps {
    label?: string;
    errorText?: string;
    value: string | null | undefined;
    placeholder?: string;
    onSelect?: () => void;
    onChange?: (
        value: string,
        data: object,
        event: React.ChangeEvent<HTMLInputElement>,
        formattedValue: string,
    ) => void;
}

export const PhoneInput: React.FC<IProps> = (props) => {
    const {
        label,
        errorText,
        value,
        placeholder,
        onChange,
    } = props;

    return (
        <div role="group" className="form-group mb-3">
            <label className="d-block">{label}</label>
            <div className="row align-items-center">
                <div className="col-sm-12">
                    <PhoneInputLib
                        inputClass="form-control"
                        placeholder={placeholder}
                        onChange={onChange}
                        value={value}
                    />
                </div>
            </div>

            {errorText && <p className="inputError">{errorText}</p>}
        </div>
    );
};
