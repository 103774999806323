import React from 'react';

import './loader.scss';

export const Loader: React.FC = () => {
    return (
        <div className="Loader">
            <div className="LoaderComponent">
                <div className="LoaderStatus">
                    <div className="Spinner">
                        <div className="SpinnerDot"></div>
                        <div className="SpinnerDot"></div>
                        <div className="SpinnerDot"></div>
                        <div className="SpinnerDot"></div>
                        <div className="SpinnerDot"></div>
                        <div className="SpinnerDot"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
