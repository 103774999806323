import React from 'react';

import './footer.scss';

export const Footer: React.FC = () => {
    return (
        <footer className="Footer">
            <div className="container">
                <p>{new Date().getFullYear()} ©</p>
            </div>
        </footer>
    );
};
