import React from 'react';

import './button.scss';

interface IProps {
    type?: 'button' | 'submit' | 'reset';
    title: string;
    btnStyles?: string;
    onClick?: () => void;
}

export const Button: React.FC<IProps> = (props) => {
    const { type = 'button', title, btnStyles, onClick } = props;

    return (
        <button type={type} className={`btn ${btnStyles}`} onClick={onClick}>
            {title}
        </button>
    );
};
