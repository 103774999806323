import React from 'react';
import { Link } from 'react-router-dom';

import './header.scss';

export const Header: React.FC = () => {
    return (
        <header className="Header">
            <div className="container">
                <Link to="/">Homepage</Link>
                <span>&nbsp;</span>
                <Link to="/profile">Profile</Link>
            </div>
        </header>
    );
};
