// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputError {
  color: red;
}

.react-tel-input .form-control {
  width: 100%;
  height: auto;
  font-size: 1rem;
  line-height: 1.5;
}`, "",{"version":3,"sources":["webpack://./src/components/PhoneInput/phoneInput.scss"],"names":[],"mappings":"AAEA;EACI,UAAA;AADJ;;AAIA;EACI,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AADJ","sourcesContent":["@import '../../sass/common/variables';\n\n.inputError {\n    color: red;\n}\n\n.react-tel-input .form-control {\n    width: 100%;\n    height: auto;\n    font-size: 1rem;\n    line-height: 1.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
