import axios from 'axios';
import { BASE_API_URL } from 'src/constants/env';

const axiosClient = axios.create({
    baseURL: BASE_API_URL,
});

// const authToken = localStorage.getItem('authToken');

axiosClient.defaults.headers.common = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

// All request will wait 10 seconds before timeout
axiosClient.defaults.timeout = 10000;

axiosClient.defaults.withCredentials = true;

export const get = (URL: string) => {
    return axiosClient.get(`${URL}`).then((response) => response);
};

export const post = (URL: string, payload: unknown) => {
    return axiosClient.post(`${URL}`, payload).then((response) => response);
};

export const patch = (URL: string, payload: unknown) => {
    return axiosClient.patch(`${URL}`, payload).then((response) => response);
};

export const _delete = (URL: string) => {
    return axiosClient.delete(`${URL}`).then((response) => response);
};

export const setAuthToken = (token: string) => {
    localStorage.setItem('authToken', token.replace('JWT ', ''));
    axiosClient.defaults.headers.common = {
        Authorization: token.replace('JWT ', ''),
    };
};

export const clearToken = () => {
    localStorage.clear();
};

const apiClient = {
    post,
    get,
    patch,
    _delete,
    setAuthToken,
};

export { apiClient };
