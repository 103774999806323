import React from 'react';

import './homeTableRow.scss';

interface IProps {
    number: number;
    name: string;
    progress?: number;
    sum: string;
}

export const HomeTableRow: React.FC<IProps> = (props) => {
    const { number, name, progress = 0, sum } = props;
    return (
        <div className="home-table-row">
            <div className="home-table-row__number">{number}</div>
            <p className="home-table-row__name">{name}</p>
            <div className="home-table-row__progress">
                <div className="home-table-row__progress-line" style={{ width: `${progress}%` }}></div>
                <div className="home-table-row__progress-number">{`${progress.toFixed(0)}%`}</div>
            </div>
            <p className="home-table-row__sum">{sum}</p>
        </div>
    );
};
