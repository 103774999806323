// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/layout.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;AADJ;;AAIA;EACI,SAAA;EACA,aAAA;EACA,sBAAA;AADJ","sourcesContent":["@import '../../sass/common/variables';\n\n.Page {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.Main {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
