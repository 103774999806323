import React from 'react';
import { ToastContainer } from 'react-toastify';

import { Router } from '../router/Router';

import './app.scss';
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
    return (
        <div className="App">
            <Router />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                rtl={false}
                newestOnTop={false}
                hideProgressBar={false}
                closeOnClick
                draggable
                pauseOnHover
                pauseOnFocusLoss
            />
        </div>
    );
};
