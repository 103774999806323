import React from 'react';

import './input.scss';

interface IProps {
    label?: string;
    errorText?: string;
    value: string | number;
    type?: string;
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input: React.FC<IProps> = (props) => {
    const {
        label,
        errorText,
        value,
        type = 'text',
        placeholder,
        onChange,
    } = props;

    return (
        <div role="group" className="form-group mb-3">
            <label className="d-block">{label}</label>
            <div className="row align-items-center">
                <div className="col-sm-12">
                    <input
                        autoComplete="new-password"
                        type={type}
                        className="form-control"
                        placeholder={placeholder}
                        onChange={onChange}
                        value={value}
                    />
                </div>
            </div>

            {errorText && <p className="inputError">{errorText}</p>}
        </div>
    );
};
