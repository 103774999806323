import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { getKPIList } from 'src/api/zoho';

export const fetchKPIList = createAsyncThunk('auth/fetch-kpi-list', async () => {
    try {
        const response = await getKPIList();

        console.log(response);

        return response.data?.data || [];
    } catch (err) {
        const error = err as AxiosError;

        console.warn(error);
        toast(error.response?.data?.message ?? 'Error', { type: 'error' });
    }
});
