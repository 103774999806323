import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchKPIList, getKPIListSelector, isLoadingZohoSelector } from 'src/store/zoho';

import { Layout, Loader } from 'src/components';
import { HomeTableRow } from './features/homeTableRow/HomeTableRow';

import { Logo2Image } from 'src/assets/img/app';

import { UserKPIType } from 'src/store/zoho/types';

import './home.scss';

export const Home: React.FC = () => {
    const dispatch = useDispatch();

    const KPIList: Array<UserKPIType> = useSelector(getKPIListSelector);
    const isLoading: boolean = useSelector(isLoadingZohoSelector);

    useEffect(() => {
        dispatch(fetchKPIList());
    }, [dispatch]);

    return (
        <Layout>
            <div className="home">
                <div className="home-container">
                    <div className="home-header">
                        <img src={Logo2Image} alt="logo" className="home-header__logo" />
                        <p className="home-header__title">РЕЙТИНГ МЕНЕДЖЕРІВ</p>
                    </div>
                    <div className="home-table">
                        <div className="home-table-header">
                            <p className="home-table-header__col">МЕНЕДЖЕР</p>
                            <p className="home-table-header__col">ФАКТ/ПЛАН</p>
                        </div>
                        <div className="home-table-body">
                            {!isLoading ? (
                                KPIList && KPIList.length ? (
                                    KPIList.map((item, index) => (
                                        <HomeTableRow
                                            key={item.Owner.id}
                                            number={index + 1}
                                            name={item.Owner.name}
                                            progress={(item.fact_dohod / item.plan_sum) * 100}
                                            sum={`$${item.fact_dohod} / $${item.plan_sum}`}
                                        />
                                    ))
                                ) : (
                                    <></>
                                )
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
