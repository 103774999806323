import React from 'react';

import './checkbox.scss';

interface IProps {
    id: string;
    name?: string;
    value?: string | number;
    type?: string;
    label: string;
    onChange?: () => void;
}

export const Checkbox: React.FC<IProps> = (props) => {
    const { id, name, value, onChange, type = 'checkbox', label } = props;

    return (
        <div>
            <input id={id} type={type} name={name} value={value} onChange={onChange} />
            <label htmlFor={id}>
                {label}
            </label>
        </div>
    );
};
