import { createSlice } from '@reduxjs/toolkit';
import { InitialStateProps } from './types';
import { fetchKPIList } from './zoho.actions';

const initialState: InitialStateProps = {
    isLoadingZoho: false,
    list: [],
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setKPIList: (state, action) => {
            state.list = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchKPIList.pending, (state) => {
            state.isLoadingZoho = true;
        });
        builder.addCase(fetchKPIList.fulfilled, (state, action) => {
            state.isLoadingZoho = false;
            state.list = action.payload;
        });
        builder.addCase(fetchKPIList.rejected, (state) => {
            state.isLoadingZoho = false;
        });
    },
});

export const { setKPIList } = authSlice.actions;

export default authSlice.reducer;
