// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.inputError {
  color: red;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before,
.react-datepicker__year-dropdown::after {
  position: absolute;
  left: 45%;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  content: "▲";
  top: 2%;
}

.react-datepicker__year-dropdown::after {
  content: "▼";
  bottom: 2%;
}

.react-datepicker__year-read-view--down-arrow {
  top: calc(50% - 6px);
}`, "",{"version":3,"sources":["webpack://./src/components/DatePicker/datePicker.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAEhB;EACI,UAAA;AAAJ;;AAGA;;EAEI,kBAAA;EACA,SAAA;AAAJ;;AAEA;EACI,YAAA;EACA,OAAA;AACJ;;AAEA;EACI,YAAA;EACA,UAAA;AACJ;;AAEA;EACI,oBAAA;AACJ","sourcesContent":["@import '../../sass/common/variables';\n\n.inputError {\n    color: red;\n}\n\n.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before,\n.react-datepicker__year-dropdown::after {\n    position: absolute;\n    left: 45%;\n}\n.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {\n    content: '\\25B2';\n    top: 2%;\n}\n\n.react-datepicker__year-dropdown::after {\n    content: '\\25BC';\n    bottom: 2%;\n}\n\n.react-datepicker__year-read-view--down-arrow {\n    top: calc(50% - 6px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
